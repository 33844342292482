<template>
    <v-card
        class="mx-auto"
        max-width="344"
        style="margin: 20px 0px; box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;"
        transition="slide-x-transition"
    >
        <v-card-text>
        <p class="text-h4 text--primary">
            QR - Code<br/>
            Einrichten
        </p>
        <div class="text--primary">
            QR-Code mit<br/>
            einer Equipmentnummer verheiraten.
        </div>
        </v-card-text>
        <v-card-actions>
        <v-btn
            text
            color="yellow darken-3"
            @click="redirect()"
        >
            Ändern
        </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script> 

import router from '../../router/router.js'

export default {
    name: 'widget_changeQR',
    methods: {
        redirect() {
            router.push({ path: '/ChangeQR' })
        }
    }
}

</script>
<template>
  <v-app id="app" style="background-color: #f2f2f2">
    <Navbar/>
    <v-main>
        <router-view/>
    </v-main>
    <Footer/>
  </v-app>
</template>

<style> 
/*#app {
  background-image: linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%);
}*/
</style>

<script>
import Navbar from './components/Navbar.vue'
import Footer from './components/Footer.vue'
import router from './router/router';

export default {
  name: 'App',

  components: {
    Navbar,
    Footer,
  },

  data: () => ({
    //
  }),

  mounted: () => {
    const queryString = window.location.href;
    const extracted_uuid = queryString.substring(27, queryString.length);
    router.push({path: '/docs/' + extracted_uuid});
  }
};
</script>

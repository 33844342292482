<template> 
    <v-container style="margin-top: 60px;">
        <v-chip label color="#48535D" style="color: white; opacity: 0.9;">Anwendungen<v-icon right>mdi-widgets</v-icon></v-chip>
        <v-row>
            <!-- <v-col
            lg="3"
            >
           <GenerateQR class="app-card"/>
            </v-col> -->

            <v-col
            lg="3"
            >
            <ChangeQR class="app-card"/>
            </v-col>

            <v-col
            lg="3"
            style="display: none;"
            >
            <SQL class="app-card"/>
            </v-col>

            <v-col
            lg="3"
            style="display: none;"
            >
            <SQL class="app-card"/>
            </v-col>
        </v-row>

        <div style="margin-top: 100px;">
        <v-chip label color="#48535D" style="color: white; opacity: 0.9;">Hilfe<v-icon right>mdi-help-circle-outline</v-icon></v-chip>
        <v-row style="margin-bottom: 100px;">
            <v-col
            lg="3"
            >
           <HelpGeneral class="app-card"/>
            </v-col>

            <!-- <v-col
            lg="3"
            > -->
            <!-- <HelpGenerate class="app-card"/> -->
            <!-- </v-col> -->

            <v-col
            lg="3"
            >
            <HelpChangeQR class="app-card"/>
            </v-col>

            <v-col
            lg="3"
            style="display: none;"
            >
            <HelpSQL class="app-card"/>
            <br/>
            </v-col>
        </v-row>
        </div>
    </v-container>
</template>

<style> 
    .app-card {
        transition: all 0.3s ease 0s;
    }

    .app-card:hover {
        opacity: 0.9;
        transform: translateY(-7px);
    }
</style>

<script> 

// import GenerateQR from './widgets/GenerateQR.vue'
import ChangeQR from './widgets/ChangeQR.vue'
import HelpGeneral from './widgets/HelpGeneral.vue'
// import HelpGenerate from './widgets/HelpGenerate.vue'
import HelpChangeQR from './widgets/HelpChangeQR.vue'
import SQL from './widgets/SQL.vue'
import HelpSQL from './widgets/HelpSQL.vue'

export default {
    name: 'comp_application_container',
    components: {
        // GenerateQR,
        ChangeQR,
        HelpGeneral,
        // HelpGenerate,
        HelpChangeQR,
        SQL,
        HelpSQL,
    }
}

</script>
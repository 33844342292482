<template>
    <v-card
        class="mx-auto"
        max-width="344"
        style="margin: 20px 0px; box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;"
    >
        <v-card-text>
        <p class="text-h4 text--primary">
            Allgemeine <br/>
            Informationen
        </p>
        <div class="text--primary">
            Allgemeine Informationen über <br/>
            das digitale Miethandbuch.
        </div>
        </v-card-text>
        <v-card-actions>
        <v-btn
            text
            color="yellow darken-3"
            @click="readHelp()"
        >
            Lesen
        </v-btn>
        </v-card-actions>
    </v-card>
</template>



<script> 
import Swal from 'sweetalert2'


export default {
    name: 'widget_help_general',
    methods: {
        readHelp() {
            Swal.fire({
                title: 'Allgemeine Informationen über das Digitale Miethandbuch',
                icon: 'info',
                html:
                '<br/>' +
                '<p style="float: left;">Die Abteilung Miete Baumaschine, möchte zur Einhaltung gesetzlicher Anforderungen die notwendigen Dokumente im Vermietungsfall, dem Mieter digital zur Verfügung stellen.</p>' +
                '<p style="float: left;> Seit jeher werden die Dokumente ausgedruckt dem Mietgegenstand beigelegt, was zu häufigem Verlust oder Zerstörung der Unterlagen führt.</p>' +
                '<p style="float: left;> Diesem Umstand soll dahingehend begegnet werden, dass der Bereitstellungsprozess der Unterlagen nun voll digital und datenbank-, bzw. archivbasiert erfolgen soll. </p>',
                confirmButtonText: 'Verstanden!',
            })
        }
    }
}

</script>
<template>
    <v-card
        class="mx-auto"
        max-width="344"
        style="margin: 20px 0px; box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;"
    >
        <v-card-text>
        <p class="text-h4 text--primary">
            Datenbank<br/>
            Statements
        </p>
        <div class="text--primary">
            Anleitung wie Sie <br/>
            SQL Statements ausführen.
        </div>
        </v-card-text>
        <v-card-actions>
        <v-btn
            text
            color="yellow darken-3"
        >
            Lesen
        </v-btn>
        </v-card-actions>
    </v-card>
</template>



<script> 

export default {
    name: 'widget_help_sql',
}

</script>
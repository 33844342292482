<template>
    <v-card
        class="mx-auto"
        max-width="344"
        style="margin: 20px 0px; box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;"
    >
        <v-card-text>
        <p class="text-h4 text--primary">
            QR - Code <br/>
            Einrichten
        </p>
        <div class="text--primary">
            Anleitung zum Verheiraten eines
            QR-Codes mit einer Equipmentnummer
        </div>
        </v-card-text>
        <v-card-actions>
        <v-btn
            text
            color="yellow darken-3"
            @click="readHelp()"
        >
            Lesen
        </v-btn>
        </v-card-actions>
    </v-card>
</template>

<style> 
.swal2-popup {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
</style>

<script> 
import Swal from 'sweetalert2'

export default {
    name: 'widget_help_changeQR',
    methods: {
        readHelp() {
            Swal.fire({
                title: 'QR-Code Ändern',
                icon: 'question',
                html: '<p>Um einen QR-Code einzurichten, <strong>scannen</strong> Sie den QR-Code und geben Sie die dazugehörige <strong>Equipmentnummer</strong> ein und klicken auf <strong style="color: #F9A825;">Verheiraten</strong>.</p>',
                confirmButtonText: 'Verstanden!'
            })
        }
    }
}

</script>
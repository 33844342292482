<template>
  <v-footer bottom fixed style="background-color: #48535D; color: white;">
    <v-col
      class="text-center"
      cols="12"
    >
      2022 — <strong>Beutlhauser</strong>
    </v-col>
  </v-footer>
</template>


<script> 

export default {
    name: 'comp_footer',
}

</script>
<template>
    <ApplicationContainer/>
</template>



<script> 

import ApplicationContainer from '../components/ApplicationContainer.vue'

export default {
    name: 'HomeView',
    components: {
        ApplicationContainer,
    }
}

</script>
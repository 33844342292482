<template>
  <div>
    <v-app-bar
      color="white"
      dark
    >
    <v-row align="center" justify="center">
    <v-col lg="3">
    </v-col>
    <v-col
      class="text-center"
      lg="6"
    >
      <v-toolbar-title class ="text-uppercase text-center d-flex flex-column align-center justify-center" style="color: black">
        <v-img src="../../public/header-logo-2022.png" style=" max-width: 272px; max-height: 22px;"></v-img>
      </v-toolbar-title>
    </v-col>
    <v-col lg="3"></v-col>
    </v-row>
    </v-app-bar>
  </div>
</template>

<script>
  export default {
    name: 'comp_navbar',
    data: () => ({

    }),
  }
</script>
